declare function gtag(...args: any[]): any;

export const CUSTOM_EVENTS = {
  USERINT_DATE_CHANGE: "userinteraction_quote_date_change",
  USERINT_POST_VISIT: "userinteraction_post_visit",
  SHARE_EVENT_FACEBOOK: "share_event_facebook",
  SHARE_EVENT_TWITTER: "share_event_twitter",
  SHARE_EVENT_LINKEDIN: "share_event_linkedin",
  COPY_BOARD_CLIPBOARD: "copy_quote_clipboard",
};

export const GAEvent = (eventName: string, data: any) => {
  /* gtag("event", "screen_view", {
    app_name: "myAppName",
    screen_name: "Home",
  }); */
  return typeof gtag === "function" && gtag("event", eventName, data);
};
