import { getQuoteDate, parseDate } from "../Util/Date";
import { IQuote, quotes } from "./QuotesDir";

const quoteKeys = Object.keys(quotes);

export function getInitDate() {
  return quoteKeys[0];
}

export function getQuoteNo(dateStr: string) {
  for (let i = 0; i < quoteKeys.length; i++) {
    if (dateStr === quoteKeys[i]) {
      return i + 1;
    }
  }
}

export function getQuote(date?: Date) {
  const dateStr = getQuoteDate(date);

  if (quotes[dateStr]) {
    return quotes[dateStr];
  } else {
    /* const keys = quoteKeys;
    const selectedKey = keys[keys.length * Math.random() << 0]
    return quotes[selectedKey]; */
    return null;
  }
};

export function matchTags(tag: string, tillToday: boolean = true) {
  const retQuotes: IQuote[] = [];
  const currDate = new Date();

  currDate.setHours(0);
  currDate.setMinutes(0);
  currDate.setSeconds(0);
  currDate.setMilliseconds(0);

  for (const key in quotes) {
    if (quotes.hasOwnProperty(key)) {
      const qDate = parseDate(key) as Date;
      if (!tillToday || qDate <= currDate) {
        if (quotes[key].tags?.indexOf(tag) > -1) {
          retQuotes.push(quotes[key]);
        }
      }
    }
  }

  return retQuotes;
}
