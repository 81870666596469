export interface IIconicLines {
  date: string;
  iconicLine: string;
  author: string;
  tags: string[];
  details?: string;
  picture?: string;
  postPicture?: string;
  embed?: string;
}

export const iconicLines: { [id: string]: IIconicLines } = {
  "9_8_2024": {
    "date": "9_8_2024",
    "iconicLine": "Chew on this, you ugly bastard.",
    "author": "Jonas Taylor (The Meg 2018)",
    "tags": ["action", "horror", "the_meg", "movie"],
    "picture": "https://ca-times.brightspotcdn.com/dims4/default/1d45468/2147483647/strip/true/crop/2940x1654+450+0/resize/1200x675!/quality/75/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2Fcd%2Ffa%2Fe28d95434cbab595d67df582050e%2Fet-meg-2-the-trench-008.JPEG",
    "details": "A group of scientists exploring the Marianas Trench encounter the largest marine predator that has ever existed - the Megalodon."
  },
  "10_8_2024": {
    "date": "10_8_2024",
    "iconicLine": "May the Force be with you.",
    "author": "Various (Star Wars)",
    "tags": ["sci-fi", "fantasy", "movie"],
    "picture": "https://mediaproxy.tvtropes.org/width/1200/https://static.tvtropes.org/pmwiki/pub/images/star_wars_logo.png",
    "details": "This iconic phrase from Star Wars has become a cultural phenomenon."
  },
  "11_8_2024": {
    "date": "11_8_2024",
    "iconicLine": "Lord Shimura: You have no honor. <br />The Ghost: And you are a slave to it.",
    "author": "Jin Sakai (Ghost Of Tsushima)",
    "tags": ["romance", "classic", "game"],
    "picture": "https://cdn.wccftech.com/wp-content/uploads/2024/03/b3iB2zf2xHj9shC0XDTULxND-HD-scaled.jpg",
    "details": "Ghost of Tsushima is an action game set in feudal Japan."
  },
  "12_8_2024": {
    "date": "12_8_2024",
    "iconicLine": "I'll be back.",
    "author": "Terminator (The Terminator)",
    "tags": ["action", "sci-fi", "movie"],
    "picture": "https://townsquare.media/site/295/files/2019/10/Terminator-Orion.jpg?w=780&q=75",
    "details": "This line became the signature catchphrase of Arnold Schwarzenegger."
  },
  "13_8_2024": {
    "date": "13_8_2024",
    "iconicLine": "Frankly, my dear, I don't give a damn.",
    "author": "Rhett Butler (Gone with the Wind)",
    "tags": ["drama", "classic", "movie"],
    "details": "Gone with the Wind, one of the most iconic films of all time, was released in 1939."
  },
  "14_8_2024": {
    "date": "14_8_2024",
    "iconicLine": "You can't handle the truth!",
    "author": "Col. Nathan R. Jessup (A Few Good Men)",
    "tags": ["drama", "courtroom", "movie"],
    "picture": "https://media.licdn.com/dms/image/C4E12AQHUL_fa1vxoNg/article-cover_image-shrink_720_1280/0/1589463548601?e=2147483647&v=beta&t=Aq2LV7aAVHkBbzW0lhAj1w_wPTcW6NNbGCOdeNTHZes",
    "details": "A powerful line delivered by Jack Nicholson in a tense courtroom drama!"
  },
  "15_8_2024": {
    "date": "15_8_2024",
    "iconicLine": "I'm the king of the world!",
    "author": "Jack Dawson (Titanic)",
    "tags": ["romance", "drama", "movie"],
    "details": "Leonardo DiCaprio shouts this line in one of Titanic's most memorable scenes."
  },
  "16_8_2024": {
    "date": "16_8_2024",
    "iconicLine": "E.T. phone home.",
    "author": "E.T. (E.T. the Extra-Terrestrial)",
    "tags": ["sci-fi", "family", "movie"],
    "details": "A touching line from the beloved film about a young boy and his alien friend."
  },
  "17_8_2024": {
    "date": "17_8_2024",
    "iconicLine": "I see dead people.",
    "author": "Cole Sear (The Sixth Sense)",
    "tags": ["thriller", "mystery", "movie"],
    "details": "A chilling line from the film that left audiences stunned with its twist ending."
  },
  "18_8_2024": {
    "date": "18_8_2024",
    "iconicLine": "Hasta la vista, baby.",
    "author": "Terminator (Terminator 2: Judgment Day)",
    "tags": ["action", "sci-fi", "movie"],
    "picture": "https://acmi-website-media-prod.s3.ap-southeast-2.amazonaws.com/media/images/Hasta_La_Vista_baby.original.jpg",
    "details": "Another iconic line from Arnold Schwarzenegger in the Terminator franchise."
  },
  "19_8_2024": {
    "date": "19_8_2024",
    "iconicLine": "There's no place like home.",
    "author": "Dorothy Gale (The Wizard of Oz)",
    "tags": ["fantasy", "family", "movie"],
    "details": "Dorothy's heartfelt realization in the classic film The Wizard of Oz."
  },
  "20_8_2024": {
    "date": "20_8_2024",
    "iconicLine": "I'm gonna make him an offer he can't refuse.",
    "author": "Vito Corleone (The Godfather)",
    "tags": ["crime", "drama", "movie"],
    "picture": "https://qph.cf2.quoracdn.net/main-qimg-480f672a523d2301977eb65d4970f2ef-lq",
    "details": "One of the most famous lines in cinema history from the iconic Godfather trilogy."
  },
  "21_8_2024": {
    "date": "21_8_2024",
    "iconicLine": "Why so serious?",
    "author": "The Joker (The Dark Knight)",
    "tags": ["action", "superhero", "movie"],
    "details": "Heath Ledger's chilling delivery as The Joker became instantly iconic."
  },
  "22_8_2024": {
    "date": "22_8_2024",
    "iconicLine": "Say hello to my little friend!",
    "author": "Tony Montana (Scarface)",
    "tags": ["crime", "drama", "movie"],
    "postPicture": "https://i.ibb.co/7NhHV0m/isq-iconiclines.png",
    "details": "Al Pacino's unforgettable performance as Tony Montana made this line legendary."
  },
  "23_8_2024": {
    "date": "23_8_2024",
    "iconicLine": "You talking to me?",
    "author": "Travis Bickle (Taxi Driver)",
    "tags": ["drama", "psychological", "movie"],
    "details": "Robert De Niro's intense delivery in Taxi Driver became an iconic moment in film."
  },
  "24_8_2024": {
    "date": "24_8_2024",
    "iconicLine": "To infinity and beyond!",
    "author": "Buzz Lightyear (Toy Story)",
    "tags": ["animation", "family", "movie"],
    "details": "Buzz Lightyear's catchphrase from Toy Story became a beloved line for all ages."
  },
  "25_8_2024": {
    "date": "25_8_2024",
    "iconicLine": "Here's Johnny!",
    "author": "Jack Torrance (The Shining)",
    "tags": ["horror", "thriller", "movie"],
    "details": "Jack Nicholson's ad-libbed line in The Shining became one of the most terrifying moments in film."
  },
  "26_8_2024": {
    "date": "26_8_2024",
    "iconicLine": "Houston, we have a problem.",
    "author": "Jim Lovell (Apollo 13)",
    "tags": ["drama", "space", "movie"],
    "details": "This line from Apollo 13 became synonymous with dealing with unexpected challenges."
  },
  "27_8_2024": {
    "date": "27_8_2024",
    "iconicLine": "I'm walking here! I'm walking here!",
    "author": "Ratso Rizzo (Midnight Cowboy)",
    "tags": ["drama", "classic", "movie"],
    "details": "Dustin Hoffman's ad-libbed line in Midnight Cowboy became a symbol of New York grit."
  },
  "28_8_2024": {
    "date": "28_8_2024",
    "iconicLine": "Carpe diem. Seize the day, boys.",
    "author": "John Keating (Dead Poets Society)",
    "tags": ["drama", "inspirational", "movie"],
    "details": "Robin Williams' character in Dead Poets Society inspires his students with this famous line."
  },
  "29_8_2024": {
    "date": "29_8_2024",
    "iconicLine": "Life is like a box of chocolates. You never know what you're gonna get.",
    "author": "Forrest Gump (Forrest Gump)",
    "tags": ["drama", "inspirational", "movie"],
    "details": "Tom Hanks' portrayal of Forrest Gump delivered this iconic and heartwarming line."
  },
  "30_8_2024": {
    "date": "30_8_2024",
    "iconicLine": "I'll have what she's having.",
    "author": "Customer (When Harry Met Sally...)",
    "tags": ["romantic", "comedy", "movie"],
    "details": "This hilarious line from When Harry Met Sally became one of the most quoted in film history."
  },
  "31_8_2024": {
    "date": "31_8_2024",
    "iconicLine": "I am your father.",
    "author": "Darth Vader (Star Wars: The Empire Strikes Back)",
    "tags": ["sci-fi", "fantasy", "movie"],
    "details": "One of the greatest plot twists in film history, revealing the true relationship between Luke and Vader."
  },
  "1_9_2024": {
    "date": "1_9_2024",
    "iconicLine": "You shall not pass!",
    "author": "Gandalf (The Lord of the Rings: The Fellowship of the Ring)",
    "tags": ["fantasy", "adventure", "movie"],
    "details": "Gandalf's iconic stand against the Balrog in The Lord of the Rings."
  },
  "2_9_2024": {
    "date": "2_9_2024",
    "iconicLine": "There's no crying in baseball!",
    "author": "Jimmy Dugan (A League of Their Own)",
    "tags": ["sports", "comedy", "movie"],
    "details": "Tom Hanks' exasperated line became one of the most quoted in sports films."
  },
  "3_9_2024": {
    "date": "3_9_2024",
    "iconicLine": "I feel the need—the need for speed!",
    "author": "Maverick (Top Gun)",
    "tags": ["action", "adventure", "movie"],
    "details": "Tom Cruise's character Maverick utters this iconic line in Top Gun."
  },
  "4_9_2024": {
    "date": "4_9_2024",
    "iconicLine": "You're gonna need a bigger boat.",
    "author": "Chief Brody (Jaws)",
    "tags": ["thriller", "suspense", "movie"],
    "details": "Roy Scheider's famous line in Jaws as the shark looms closer."
  },
  "5_9_2024": {
    "date": "5_9_2024",
    "iconicLine": "Keep your friends close, but your enemies closer.",
    "author": "Michael Corleone (The Godfather Part II)",
    "tags": ["crime", "drama", "movie"],
    "details": "This strategic advice from Michael Corleone became a widely quoted line."
  },
  "6_9_2024": {
    "date": "6_9_2024",
    "iconicLine": "It's alive! It's alive!",
    "author": "Dr. Frankenstein (Frankenstein)",
    "tags": ["horror", "classic", "movie"],
    "details": "This line from Frankenstein marked one of the most iconic moments in horror cinema."
  },
  "7_9_2024": {
    "date": "7_9_2024",
    "iconicLine": "My precious.",
    "author": "Gollum (The Lord of the Rings: The Two Towers)",
    "tags": ["fantasy", "adventure", "movie"],
    "details": "Gollum's obsession with the One Ring is perfectly captured in this line."
  },
  "8_9_2024": {
    "date": "8_9_2024",
    "iconicLine": "Bond. James Bond.",
    "author": "James Bond (Dr. No)",
    "tags": ["action", "spy", "movie"],
    "details": "The introduction of 007 in Dr. No became a hallmark of the Bond series."
  },
  "9_9_2024": {
    "date": "9_9_2024",
    "iconicLine": "I'm as mad as hell, and I'm not going to take this anymore!",
    "author": "Howard Beale (Network)",
    "tags": ["drama", "classic", "satire", "movie"],
    "details": "An iconic outburst from the film Network."
  },
  "10_9_2024": {
    "date": "10_9_2024",
    "iconicLine": "They call it a Royale with Cheese.",
    "author": "Vincent Vega (Pulp Fiction)",
    "tags": ["crime", "drama", "movie"],
    "details": "A famous conversation about cultural differences in Quentin Tarantino's Pulp Fiction."
  },
  "11_9_2024": {
    "date": "11_9_2024",
    "iconicLine": "You're killing me, Smalls!",
    "author": "Hamilton 'Ham' Porter (The Sandlot)",
    "tags": ["comedy", "family", "movie"],
    "details": "A memorable line from the beloved family film The Sandlot."
  },
  "12_9_2024": {
    "date": "12_9_2024",
    "iconicLine": "The first rule of Fight Club is: You do not talk about Fight Club.",
    "author": "Tyler Durden (Fight Club)",
    "tags": ["drama", "thriller", "movie"],
    "details": "This line from Fight Club became a catchphrase for secrecy and rebellion."
  },
  "13_9_2024": {
    "date": "13_9_2024",
    "iconicLine": "I am serious... and don't call me Shirley.",
    "author": "Dr. Rumack (Airplane!)",
    "tags": ["comedy", "classic", "movie"],
    "details": "Leslie Nielsen's deadpan delivery in Airplane! made this one of the funniest lines in film history."
  },
  "14_9_2024": {
    "date": "14_9_2024",
    "iconicLine": "Life is a banquet, and most poor suckers are starving to death!",
    "author": "Auntie Mame (Auntie Mame)",
    "tags": ["comedy", "drama", "movie"],
    "details": "Auntie Mame's vivacious outlook on life is captured in this famous line."
  },
  "15_9_2024": {
    "date": "15_9_2024",
    "iconicLine": "Yippee-ki-yay, motherf***er!",
    "author": "John McClane (Die Hard)",
    "tags": ["action", "thriller", "movie"],
    "details": "Bruce Willis' character John McClane utters this line in Die Hard, solidifying his action hero status."
  },
  "16_9_2024": {
    "date": "16_9_2024",
    "iconicLine": "If you build it, he will come.",
    "author": "The Voice (Field of Dreams)",
    "tags": ["drama", "fantasy", "movie"],
    "details": "This mysterious line from Field of Dreams became a symbol of faith and belief."
  },
  "17_9_2024": {
    "date": "17_9_2024",
    "iconicLine": "You is kind. You is smart. You is important.",
    "author": "Aibileen Clark (The Help)",
    "tags": ["drama", "inspirational", "movie"],
    "details": "Aibileen's words of encouragement in The Help became an empowering moment."
  },
  "18_9_2024": {
    "date": "18_9_2024",
    "iconicLine": "Just keep swimming.",
    "author": "Dory (Finding Nemo)",
    "tags": ["animation", "family", "movie"],
    "details": "Dory's optimistic mantra in Finding Nemo became a motivational phrase for many."
  },
  "19_9_2024": {
    "date": "19_9_2024",
    "iconicLine": "I coulda been a contender.",
    "author": "Terry Malloy (On the Waterfront)",
    "tags": ["drama", "classic", "movie"],
    "details": "Marlon Brando's lament in On the Waterfront is one of the most poignant lines in cinema."
  },
  "20_9_2024": {
    "date": "20_9_2024",
    "iconicLine": "Nobody puts Baby in a corner.",
    "author": "Johnny Castle (Dirty Dancing)",
    "tags": ["romance", "drama", "movie"],
    "details": "Patrick Swayze's line in Dirty Dancing became a declaration of love and defiance.",
    "embed": `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Finspiredailyquotes.online%2Fposts%2Fpfbid0AT7oAeosJxoRNynhFSvVuq2vAVncocqvNoF4EbrbxY84MNMcR6RnoAvXHDZzX5rLl&show_text=true&width=500" width="500" height="329" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>`
  },
  "21_9_2024": {
    "date": "21_9_2024",
    "iconicLine": "Keep the change, ya filthy animal.",
    "author": "Gangster Johnny (Angels with Filthy Souls in Home Alone)",
    "tags": ["comedy", "holiday", "movie"],
    "details": "A memorable line from the fictional gangster film within Home Alone."
  },
  "22_9_2024": {
    "date": "22_9_2024",
    "iconicLine": "Life moves pretty fast. If you don't stop and look around once in a while, you could miss it.",
    "author": "Ferris Bueller (Ferris Bueller's Day Off)",
    "tags": ["comedy", "coming_of_age", "FerrisBueller", "FerrisBuellersDayOff", "movie"],
    "details": "Ferris Bueller's philosophy on life has resonated with audiences for decades.",
    "embed": `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Finspiredailyquotes.online%2Fposts%2Fpfbid035tifL25NYTAuF8Ve3Quw4uTW9GqPJ8Mne5UsMHDNufmYiDZHP893n3fsSLsLmmdil&show_text=true&width=500" width="500" height="660" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>`
  },
  "23_9_2024": {
    "date": "23_9_2024",
    "iconicLine": "Go ahead, make my day",
    "author": "Harry Callahan (Sudden Impact)",
    "tags": ["action", "drama", "crime", "SuddenImpact", "HarryCallahan", "movie"],
    "details": "When a woman exacts deadly revenge on the aggressors who raped her and her sister ten years earlier, Inspector \"Dirty\" Harry Callahan is assigned to the case."
  },
  "24_9_2024": {
    "date": "24_9_2024",
    "iconicLine": "You complete me.",
    "author": "Jerry Maguire (Jerry Maguire)",
    "tags": ["romance", "drama", "JerryMaguire", "TomCruise", "movie"],
    "details": "Tom Cruise's heartfelt line became one of the most quoted romantic moments in film."
  },
  "25_9_2024": {
    "date": "25_9_2024",
    "iconicLine": "Elementary, my dear Watson.",
    "author": "Sherlock Holmes (The Adventures of Sherlock Holmes)",
    "tags": ["mystery", "classic", "SherlockHolmes", "movie"],
    "details": "Although this line was never said by Sherlock Holmes in the original stories, it became famous in the 1939 film."
  },
  "26_9_2024": {
    "date": "26_9_2024",
    "iconicLine": "I drink your milkshake!",
    "author": "Daniel Plainview (There Will Be Blood)",
    "tags": ["drama", "classic", "ThereWillBeBlood", "DanielPlainview", "movie"],
    "details": "Daniel Day-Lewis' intense performance in There Will Be Blood made this line unforgettable."
  },
  "27_9_2024": {
    "date": "27_9_2024",
    "iconicLine": "Every time a bell rings, an angel gets his wings.",
    "author": "Zuzu Bailey (It's a Wonderful Life)",
    "tags": ["holiday", "classic", "ItsAWonderfulLife", "ZuzuBailey", "movie"],
    "postPicture": "https://i.ibb.co/b3cFPm5/isq-iconiclines.png",
    "details": "This line from It's a Wonderful Life has become synonymous with the holiday season."
  },
  "28_9_2024": {
    "date": "28_9_2024",
    "iconicLine": "I am Groot.",
    "author": "Groot (Guardians of the Galaxy)",
    "tags": ["action", "sci-fi", "marvel", "GuardiansOfTheGalaxy", "Groot", "movie"],
    "details": "Despite only saying these three words, Groot's line became a cultural phenomenon."
  },
  "29_9_2024": {
    "date": "25_9_2024",
    "iconicLine": "What we've got here is failure to communicate.",
    "author": "Captain (Cool Hand Luke)",
    "tags": ["drama", "classic", "CoolHandLuke", "Captain", "movie"],
    "details": "This line from Cool Hand Luke became emblematic of authority and rebellion, also the first line from famous Guns and Roses track civil war."
  },
  "30_9_2024": {
    "date": "30_9_2024",
    "iconicLine": "You had me at 'hello'.",
    "author": "Dorothy Boyd (Jerry Maguire)",
    "tags": ["romance", "drama", "JerryMaguire", "DorothyBoyd", "movie"],
    "details": "Renee Zellweger's heartfelt line in Jerry Maguire became one of the most memorable romantic moments."
  },
  "1_10_2024": {
    "date": "1_10_2024",
    "iconicLine": "Here's looking at you, kid.",
    "author": "Rick Blaine (Casablanca)",
    "tags": ["romance", "classic", "Casablanca", "RickBlaine", "movie"],
    "details": "Casablanca is widely regarded as one of the greatest films ever made."
  },
  "2_10_2024": {
    "date": "2_10_2024",
    "iconicLine": "Why is it, when something happens, it is always you three?",
    "author": "Minerva McGonagall (Harry Potter and the Half-Blood Prince)",
    "tags": ["action", "magic", "fantasy","HarryPotter", "movie"],
    "details": "Paying tribute to Dame Maggie Smith (1934 — 2024); Minerva McGonagall wondering where the trouble starts."
  },
  "3_10_2024": {
    "date": "3_10_2024",
    "iconicLine": "Magic Mirror on the wall, who is the fairest one of all?",
    "author": "The Evil Queen (Snow White and the Seven Dwarfs)",
    "tags": ["animation", "fantasy", "family"],
    "details": "A famous line from Disney's Snow White."
  },
  "4_10_2024": {
    "date": "4_10_2024",
    "iconicLine": "It's a trap!",
    "author": "Admiral Ackbar (Star Wars: Return of the Jedi)",
    "tags": ["sci-fi", "fantasy", "action"],
    "details": "A famous line from the Star Wars saga."
  },
  "5_10_2024": {
    "date": "5_10_2024",
    "iconicLine": "I am Iron Man.",
    "author": "Tony Stark (Iron Man)",
    "tags": ["superhero", "action", "sci-fi"],
    "details": "Robert Downey Jr.'s famous line from Iron Man."
  },
  "6_10_2024": {
    "date": "6_10_2024",
    "iconicLine": "As if!",
    "author": "Cher Horowitz (Clueless)",
    "tags": ["comedy", "teen", "classic"],
    "details": "A memorable line from the teen comedy Clueless."
  },
  "7_10_2024": {
    "date": "7_10_2024",
    "iconicLine": "Why don't you come up sometime and see me?",
    "author": "Lady Lou (She Done Him Wrong)",
    "tags": ["classic", "drama", "comedy"],
    "details": "Mae West's seductive line from She Done Him Wrong."
  },
  "8_10_2024": {
    "date": "8_10_2024",
    "iconicLine": "Show me the money!",
    "author": "Rod Tidwell (Jerry Maguire)",
    "tags": ["drama", "comedy", "sports"],
    "details": "A famous line from Jerry Maguire."
  },
  "9_10_2024": {
    "date": "9_10_2024",
    "iconicLine": "I'm having an old friend for dinner.",
    "author": "Hannibal Lecter (The Silence of the Lambs)",
    "tags": ["thriller", "horror", "classic"],
    "details": "Anthony Hopkins' chilling line from The Silence of the Lambs."
  },
  "10_10_2024": {
    "date": "10_10_2024",
    "iconicLine": "I'm not bad. I'm just drawn that way.",
    "author": "Jessica Rabbit (Who Framed Roger Rabbit)",
    "tags": ["animation", "noir", "comedy"],
    "details": "Jessica Rabbit's famous line from Who Framed Roger Rabbit."
  },
  "11_10_2024": {
    "date": "11_10_2024",
    "iconicLine": "Every man dies, not every man really lives.",
    "author": "William Wallace (Braveheart)",
    "tags": ["drama", "war", "inspirational"],
    "details": "A motivational line from Braveheart."
  },
  "12_10_2024": {
    "date": "12_10_2024",
    "iconicLine": "I wish I knew how to quit you.",
    "author": "Jack Twist (Brokeback Mountain)",
    "tags": ["romance", "drama", "classic"],
    "details": "A heartbreaking line from Brokeback Mountain."
  },
  "13_10_2024": {
    "date": "13_10_2024",
    "iconicLine": "I'm just one stomach flu away from my goal weight.",
    "author": "Emily Charlton (The Devil Wears Prada)",
    "tags": ["comedy", "drama", "fashion"],
    "details": "A sarcastic line from The Devil Wears Prada."
  },
  "14_10_2024": {
    "date": "14_10_2024",
    "iconicLine": "After all, tomorrow is another day.",
    "author": "Scarlett O'Hara (Gone with the Wind)",
    "tags": ["drama", "romance", "classic"],
    "details": "A hopeful line from Gone with the Wind."
  },
  "15_10_2024": {
    "date": "15_10_2024",
    "iconicLine": "They're here!",
    "author": "Carol Anne (Poltergeist)",
    "tags": ["horror", "thriller", "classic"],
    "details": "A chilling line from the movie Poltergeist."
  },
  "16_10_2024": {
    "date": "16_10_2024",
    "iconicLine": "You make me want to be a better man.",
    "author": "Melvin Udall (As Good as It Gets)",
    "tags": ["romance", "drama", "comedy"],
    "details": "Jack Nicholson's touching line from As Good as It Gets."
  },
  "17_10_2024": {
    "date": "17_10_2024",
    "iconicLine": "Snap out of it!",
    "author": "Loretta Castorini (Moonstruck)",
    "tags": ["romance", "comedy", "classic"],
    "details": "Cher's famous line from the romantic comedy Moonstruck."
  },
  "18_10_2024": {
    "date": "18_10_2024",
    "iconicLine": "Judge the work, and leave judgment concerning those who wrought it to the judge who sees all things.",
    "author": "Cirdan (Rings of power S2 E1)",
    "tags": ["action", "fantacy", "classic"],
    "details": "Beautiful line which just hits right"
  },
  "19_10_2024": {
    "date": "19_10_2024",
    "iconicLine": "Toto, I've got a feeling we're not in Kansas anymore",
    "author": "Dorothy Gale (The Wizard of Oz)",
    "tags": ["Wizard", "comedy", "classic"],
    "details": "Young Dorothy Gale and her dog Toto are swept away by a tornado from their Kansas farm to the magical Land of Oz and embark on a quest with three new friends to see the Wizard, who can return her to her home and fulfill the others' wishes."
  },
  "20_10_2024": {
    "date": "20_10_2024",
    "iconicLine": "Of all the gin joints in all the towns in all the world, she walks into mine.",
    "author": "Rick Blaine (Casablanca)",
    "tags": ["classic", "destiny"],
    "details": "A cynical expatriate American cafe owner struggles to decide whether or not to help his former lover and her fugitive husband escape the Nazis in French Morocco."
  },
  "21_10_2024": {
    "date": "21_10_2024",
    "iconicLine": "Rosebud.",
    "author": "Charles Foster Kane (Citizen Kane)",
    "tags": ["epic", "drama", "mystery"],
    "details": "Following the death of publishing tycoon Charles Foster Kane, reporters scramble to uncover the meaning of his final utterance: 'Rosebud.'"
  },
  "22_10_2024": {
    "date": "22_10_2024",
    "iconicLine": "All right, Mr. DeMille, I'm ready for my close-up.",
    "author": "Norma Desmond (Sunset Boulevard)",
    "tags": ["Tragedy", "Drama", "Psychological Drama"],
    "details": "A screenwriter develops a dangerous relationship with a faded film star determined to make a triumphant return."
  },
  "23_10_2024": {
    "date": "23_10_2024",
    "iconicLine": "Inconceivable!",
    "author": "Vizzini (The Princess Bride)",
    "tags": ["fantasy", "comedy", "adventure"],
    "details": "A memorable line from The Princess Bride."
  },
  "24_10_2024": {
    "date": "24_10_2024",
    "iconicLine": "I love the smell of napalm in the morning.",
    "author": "Lt. Col. Bill Kilgore (Apocalypse Now)",
    "tags": ["epic", "quest", "war"],
    "details": "A U.S. Army officer serving in Vietnam is tasked with assassinating a renegade Special Forces Colonel who sees himself as a god."
  },
  "25_10_2024": {
    "date": "25_10_2024",
    "iconicLine": "You can't fight in here! This is the War Room!",
    "author": "President Muffley (Dr. Strangelove)",
    "tags": ["comedy", "war", "classic"],
    "details": "A satirical line from Stanley Kubrick's Dr. Strangelove."
  },
  "26_10_2024": {
    "date": "26_10_2024",
    "iconicLine": "Fasten your seatbelts. It's going to be a bumpy night.",
    "author": "Margo Channing (All About Eve)",
    "tags": ["drama", "classic", "romance"],
    "details": "A famous quote from All About Eve."
  },
  "27_10_2024": {
    "date": "27_10_2024",
    "iconicLine": "A census taker once tried to test me. I ate his liver with some fava beans and a nice Chianti.",
    "author": "Hannibal Lecter (The Silence of the Lambs)",
    "tags": ["crime", "thriller", "scare"],
    "details": "A young F.B.I. cadet must receive the help of an incarcerated and manipulative cannibal killer to help catch another serial killer, a madman who skins his victims."
  },
  "28_10_2024": {
    "date": "28_10_2024",
    "iconicLine": "Forget it, Jake, it's Chinatown.",
    "author": "Lawrence Walsh (Chinatown)",
    "tags": ["drama", "tragedy", "thriller"],
    "details": "A private detective hired to expose an adulterer in 1930s Los Angeles finds himself caught up in a web of deceit, corruption, and murder."
  },
  "29_10_2024": {
    "date": "29_10_2024",
    "iconicLine": "Play it, Sam. Play 'As Time Goes By.'",
    "author": "Ilsa Lund (Casablanca)",
    "tags": ["drama", "romance", "war"],
    "details": "A cynical expatriate American cafe owner struggles to decide whether or not to help his former lover and her fugitive husband escape the Nazis in French Morocco."
  },
  "30_10_2024": {
    "date": "30_10_2024",
    "iconicLine": "It's not the years, honey, it's the mileage.",
    "author": "Indiana Jones (Raiders of the Lost Ark)",
    "tags": ["adventure", "action", "classic"],
    "details": "A famous line from Raiders of the Lost Ark."
  },
  "31_10_2024": {
    "date": "31_10_2024",
    "iconicLine": "The stuff that dreams are made of.",
    "author": "Sam Spade (The Maltese Falcon)",
    "tags": ["crime", "romance", "mystery"],
    "details": "A classic line from Terminator 2."
  },
  "1_11_2024": {
    "date": "1_11_2024",
    "iconicLine": "I could tell you, but then I'd have to kill you.",
    "author": "Lt. Cdr. Tom 'Iceman' Kazansky (Top Gun)",
    "tags": ["action", "drama", "comedy"],
    "details": "A memorable quote from Top Gun."
  }
};
