import React from 'react';
import { Container, Typography, CircularProgress, Paper, Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LinkIcon from '@mui/icons-material/Link';
import { getQuote } from '../Quotes/Main';
import { CUSTOM_EVENTS, GAEvent } from './GoogleAnalyticsEvents';
import { dateFormat, getQuoteDate } from '../Util/Date';
import { Link } from 'react-router-dom';
import { initSEO } from '../Util/SEOInit';

export interface IQuoteParams {
  date?: Date;
}

const Quote = ({ date }: IQuoteParams) => {
  let loading = false;
  const quoteDetails = getQuote(date);

  const shareUrlDecoded = `${window.location.protocol}//${window.location.host}/date/${getQuoteDate(date)}`;
  const shareUrl = encodeURIComponent(shareUrlDecoded);
  const shareText = encodeURIComponent("Checkout this awesome quote by " + quoteDetails?.author + " on " + shareUrlDecoded);

  initSEO({
    title: "Quote of the day : " + dateFormat(date || new Date()) + " by " + quoteDetails?.author,
    additionalKeywords: quoteDetails?.author + ", " + quoteDetails?.tags.join(", "),
    description: `Checkout this awesome quote: "${quoteDetails?.quote}" from ${quoteDetails?.author}`,
    image: quoteDetails?.postPicture || quoteDetails?.picture
  });

  const onShareClick = (type: string) => {
    let url = '';
    switch (type) {
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&t=${shareText}`;
        GAEvent(CUSTOM_EVENTS.SHARE_EVENT_FACEBOOK, { url: shareUrlDecoded });
        window.open(url, '_blank');
        break;
      case 'twitter':
        url = `https://twitter.com/intent/tweet?source=${shareUrl}&text=${shareText}`;
        GAEvent(CUSTOM_EVENTS.SHARE_EVENT_TWITTER, { url: shareUrlDecoded });
        window.open(url, '_blank');
        break;
      case 'linkedin':
        url = `http://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${shareText}&summary=&source=${shareUrl}`;
        GAEvent(CUSTOM_EVENTS.SHARE_EVENT_LINKEDIN, { url: shareUrlDecoded });
        window.open(url, '_blank');
        break;
    }
  };

  const onCopyLinkClick = () => {
    window.navigator.clipboard?.writeText(shareUrlDecoded);
    GAEvent(CUSTOM_EVENTS.COPY_BOARD_CLIPBOARD, { url: shareUrlDecoded });
  };

  return (
    <Container maxWidth="sm" sx={{ marginBottom: 5, marginTop: 5 }}>
      <Paper elevation={5} style={{
        borderRadius: '15px',
        padding: '2rem',
        textAlign: 'center',
        backgroundColor: '#f5f5f5',
        opacity: 0.9,
        color: "#000"
      }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {quoteDetails ? <>
              <Typography variant="h5" gutterBottom>
                <span dangerouslySetInnerHTML={{ __html: quoteDetails.quote }}></span>
              </Typography>
              <Typography variant="subtitle1" color="" style={{ marginBottom: '20px' }}>
                - {quoteDetails.author}
              </Typography>

              {quoteDetails.postPicture ? <img className="post-image" src={quoteDetails.postPicture} alt="main quote post" /> : null}

              {quoteDetails.embed ? <div dangerouslySetInnerHTML={{ __html: quoteDetails.embed }}></div> : null}

              {quoteDetails.details ? <Typography variant="subtitle2" color="" style={{ marginBottom: '20px' }}>
                {quoteDetails.details}
              </Typography> : null}

              {quoteDetails.tags ?
                <Typography variant="subtitle2" color="" style={{ marginBottom: '20px', 'wordBreak': 'break-word' }}>
                  {quoteDetails.tags.map((el) => {
                    return <React.Fragment key={el}><Link to={`/quotes/tags/${el}`}>{`#${el}`}</Link>&nbsp;&nbsp;</React.Fragment>
                  })}
                </Typography>
                : null}

              <Button variant='outlined' endIcon={<FacebookIcon />} style={{ margin: '5px' }}
                onClick={() => onShareClick('facebook')}>
                Share
              </Button>

              <Button variant='outlined' endIcon={<TwitterIcon />} style={{ margin: '5px' }}
                onClick={() => onShareClick('twitter')}>
                Share
              </Button>

              <Button variant='outlined' endIcon={<LinkedInIcon />} style={{ margin: '5px' }}
                onClick={() => onShareClick('linkedin')}>
                Share
              </Button>

              <Button variant='outlined' endIcon={<LinkIcon />} style={{ margin: '5px' }}
                onClick={() => onCopyLinkClick()}>
                Copy Link
              </Button>
            </> : <Typography variant="h5" gutterBottom>
              No quote found for the day, go back to main page.
            </Typography>}
          </>
        )}
      </Paper>
    </Container>
  );
};

export default Quote;
