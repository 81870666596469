import { getQuoteDate, parseDate } from "../Util/Date";
import { iconicLines, IIconicLines } from "./IconicLinesDir";

const iconicLinesKeys = Object.keys(iconicLines);

export function getInitDate() {
  return iconicLinesKeys[0];
}

export function getIconicLineNo(dateStr: string) {
  for (let i = 0; i < iconicLinesKeys.length; i++) {
    if (dateStr === iconicLinesKeys[i]) {
      return i + 1;
    }
  }
}

export function getIconicLine(date?: Date) {
  const dateStr = getQuoteDate(date);

  if (iconicLines[dateStr]) {
    return iconicLines[dateStr];
  } else {
    /* const keys = iconicLinesKeys;
    const selectedKey = keys[keys.length * Math.random() << 0]
    return quotes[selectedKey]; */
    return null;
  }
};

export function matchTagsLines(tag: string, tillToday: boolean = true) {
  const retLines: IIconicLines[] = [];
  const currDate = new Date();

  currDate.setHours(0);
  currDate.setMinutes(0);
  currDate.setSeconds(0);
  currDate.setMilliseconds(0);

  for (const key in iconicLines) {
    if (iconicLines.hasOwnProperty(key)) {
      const qDate = parseDate(key) as Date;
      if (!tillToday || qDate <= currDate) {
        if (iconicLines[key].tags?.indexOf(tag) > -1) {
          retLines.push(iconicLines[key]);
        }
      }
    }
  }

  return retLines;
}
