export interface IQuote {
  date: string;
  quote: string;
  author: string;
  tags: string[];
  details?: string;
  picture?: string;
  postPicture?: string;
  embed?: string;
}

export const quotes: { [id: string]: IQuote } = {
  "4_8_2024": {
    "date": "4_8_2024",
    "quote": "That's what death is, isn’t it? Forgetting. Being forgotten. If we forget where we've been and what we've done, we're not men anymore. Just animals.",
    "author": "Samwell Tarly (Game Of Thrones)",
    "tags": ["fantasy", "game_of_thrones", "quote"]
  },
  "5_8_2024": {
    "date": "5_8_2024",
    "quote": "The only limit to our realization of tomorrow is our doubts of today.",
    "author": "Franklin D. Roosevelt",
    "tags": ["inspiration", "future", "quote"]
  },
  "6_8_2024": {
    "date": "6_8_2024",
    "quote": "If the radiance of a thousand suns were to burst at once into the sky...That would be like the splendour of the Mighty One...I am become Death, The shatterer of worlds.",
    "author": "J. Robert Oppenheimer",
    "tags": ["world_war_2", "atom_bomb", "quote"],
    "details": "On this day in 1945, the United States dropped an atomic bomb on Hiroshima, Japan—the blast killed more than 70,000 people and destroyed most of the city—in an effort to hasten the end of World War II.",
  },
  "7_8_2024": {
    "date": "7_8_2024",
    "quote": "In the end, it's not the years in your life that count. It's the life in your years.",
    "author": "Abraham Lincoln",
    "tags": ["life", "time", "quote"]
  },
  "8_8_2024": {
    "date": "8_8_2024",
    "quote": "You miss 100% of the shots you don't take.",
    "author": "Wayne Gretzky",
    "tags": ["inspiration", "sports", "quote"]
  },
  "9_8_2024": {
    "date": "9_8_2024",
    "quote": "Whether you think you can or you think you can't, you're right.",
    "author": "Henry Ford",
    "picture": "https://assets.editorial.aetnd.com/uploads/2009/11/henry-ford-gettyimages-3230625.jpg",
    "tags": ["mindset", "motivation", "quote"]
  },
  "10_8_2024": {
    "date": "10_8_2024",
    "quote": "To be yourself in a world that is constantly trying to make you something else is the greatest accomplishment.",
    "author": "Ralph Waldo Emerson",
    "tags": ["individuality", "society", "quote"]
  },
  "11_8_2024": {
    "date": "11_8_2024",
    "quote": "The only way to do great work is to love what you do.",
    "author": "Steve Jobs",
    "picture": "https://hips.hearstapps.com/hmg-prod/images/apple-ceo-steve-jobs-speaks-during-an-apple-special-event-news-photo-1683661736.jpg?crop=0.800xw:0.563xh;0.0657xw,0.0147xh&resize=1200:*",
    "tags": ["work", "passion", "quote"]
  },
  "12_8_2024": {
    "date": "12_8_2024",
    "quote": "Success is not final, failure is not fatal: It is the courage to continue that counts.",
    "author": "Winston Churchill",
    "tags": ["success", "perseverance", "quote"]
  },
  "13_8_2024": {
    "date": "13_8_2024",
    "quote": "Believe you can and you're halfway there.",
    "author": "Theodore Roosevelt",
    "tags": ["belief", "motivation", "quote"]
  },
  "14_8_2024": {
    "date": "14_8_2024",
    "quote": "The future belongs to those who believe in the beauty of their dreams.",
    "author": "Eleanor Roosevelt",
    "tags": ["future", "dreams", "quote"]
  },
  "15_8_2024": {
    "date": "15_8_2024",
    "quote": "Don't watch the clock; do what it does. Keep going.",
    "author": "Sam Levenson",
    "tags": ["time", "persistence", "quote"]
  },
  "16_8_2024": {
    "date": "16_8_2024",
    "quote": "Knowledge is power!",
    "author": "Invoker (Dota 2)",
    "tags": ["game", "dota2", "Knowledge", "quote"],
    "picture": "https://miro.medium.com/v2/resize:fit:1400/1*M8WpCjTWbANmxIx9K3crAA.jpeg"
  },
  "17_8_2024": {
    "date": "17_8_2024",
    "quote": "Power is power.",
    "author": "Cersei Lannister (Game of Thrones)",
    "tags": ["life", "power", "quote"],
    "picture": "https://miro.medium.com/v2/resize:fit:1400/1*n0awqpiYB-jE36xO-F0Rhg.jpeg",
  },
  "18_8_2024": {
    "date": "18_8_2024",
    "quote": "Your time is limited, don't waste it living someone else's life.",
    "author": "Steve Jobs",
    "picture": "https://i.insider.com/656e93d10ec98e92f74c0510?width=700",
    "tags": ["time", "individuality", "quote"]
  },
  "19_8_2024": {
    "date": "19_8_2024",
    "quote": "The greatest glory in living lies not in never falling, but in rising every time we fall.",
    "author": "Nelson Mandela",
    "tags": ["perseverance", "glory", "quote"]
  },
  "20_8_2024": {
    "date": "20_8_2024",
    "quote": "The way to get started is to quit talking and begin doing.",
    "author": "Walt Disney",
    "tags": ["action", "motivation", "quote"]
  },
  "21_8_2024": {
    "date": "21_8_2024",
    "quote": "Don't judge each day by the harvest you reap but by the seeds that you plant.",
    "author": "Robert Louis Stevenson",
    "tags": ["effort", "patience", "quote"]
  },
  "22_8_2024": {
    "date": "22_8_2024",
    "quote": "It does not matter how slowly you go as long as you do not stop.",
    "author": "Confucius",
    "tags": ["perseverance", "persistence", "quote"]
  },
  "23_8_2024": {
    "date": "23_8_2024",
    "quote": "Our lives begin to end the day we become silent about things that matter.",
    "author": "Martin Luther King Jr.",
    "tags": ["courage", "voice", "quote"]
  },
  "24_8_2024": {
    "date": "24_8_2024",
    "quote": "The only impossible journey is the one you never begin.",
    "author": "Tony Robbins",
    "tags": ["journey", "action", "quote"]
  },
  "25_8_2024": {
    "date": "25_8_2024",
    "quote": "Act as if what you do makes a difference. It does.",
    "author": "William James",
    "tags": ["action", "impact", "quote"]
  },
  "26_8_2024": {
    "date": "26_8_2024",
    "quote": "Success usually comes to those who are too busy to be looking for it.",
    "author": "Henry David Thoreau",
    "tags": ["success", "busyness", "quote"]
  },
  "27_8_2024": {
    "date": "27_8_2024",
    "quote": "Opportunities don't happen. You create them.",
    "author": "Chris Grosser",
    "tags": ["opportunity", "action", "quote"]
  },
  "28_8_2024": {
    "date": "28_8_2024",
    "quote": "Don't be afraid to give up the good to go for the great.",
    "author": "John D. Rockefeller",
    "tags": ["risk", "greatness", "quote"]
  },
  "29_8_2024": {
    "date": "29_8_2024",
    "quote": "I find that the harder I work, the more luck I seem to have.",
    "author": "Thomas Jefferson",
    "tags": ["hard work", "luck", "quote"]
  },
  "30_8_2024": {
    "date": "30_8_2024",
    "quote": "Success is not in what you have, but who you are.",
    "author": "Bo Bennett",
    "tags": ["success", "character", "quote"]
  },
  "31_8_2024": {
    "date": "31_8_2024",
    "quote": "The only place where success comes before work is in the dictionary.",
    "author": "Vidal Sassoon",
    "tags": ["work", "success", "quote"]
  },
  "1_9_2024": {
    "date": "1_9_2024",
    "quote": "Don't count the days, make the days count.",
    "author": "Muhammad Ali",
    "tags": ["time", "motivation", "quote"]
  },
  "2_9_2024": {
    "date": "2_9_2024",
    "quote": "Do not wait to strike till the iron is hot; but make it hot by striking.",
    "author": "William Butler Yeats",
    "tags": ["action", "opportunity", "quote"]
  },
  "3_9_2024": {
    "date": "3_9_2024",
    "quote": "Great minds discuss ideas; average minds discuss events; small minds discuss people.",
    "author": "Eleanor Roosevelt",
    "tags": ["ideas", "discussion", "quote"]
  },
  "4_9_2024": {
    "date": "4_9_2024",
    "quote": "The best revenge is massive success.",
    "author": "Frank Sinatra",
    "tags": ["success", "revenge", "quote"]
  },
  "5_9_2024": {
    "date": "5_9_2024",
    "quote": "The only way to achieve the impossible is to believe it is possible.",
    "author": "Charles Kingsleigh",
    "tags": ["belief", "possibility", "quote"]
  },
  "6_9_2024": {
    "date": "6_9_2024",
    "quote": "The biggest risk is not taking any risk.",
    "author": "Mark Zuckerberg",
    "tags": ["risk", "action", "quote"]
  },
  "7_9_2024": {
    "date": "7_9_2024",
    "quote": "Your life does not get better by chance, it gets better by change.",
    "author": "Jim Rohn",
    "tags": ["change", "life", "quote"]
  },
  "8_9_2024": {
    "date": "8_9_2024",
    "quote": "When you are not practicing, remember, someone somewhere is, and when you meet him he is going to win.",
    "author": "Ed Macauley",
    "tags": ["practice", "hardwork", "quote"]
  },
  "9_9_2024": {
    "date": "9_9_2024",
    "quote": "Be yourself; everyone else is already taken.",
    "author": "Oscar Wilde",
    "tags": ["authenticity", "self", "wisdom", "quote"]
  },
  "10_9_2024": {
    "date": "10_9_2024",
    "quote": "Hard work beats talent when talent doesn't work hard.",
    "author": "Tim Notke",
    "tags": ["hard work", "talent", "quote"]
  },
  "11_9_2024": {
    "date": "11_9_2024",
    "quote": "Do not go where the path may lead, go instead where there is no path and leave a trail.",
    "author": "Ralph Waldo Emerson",
    "tags": ["inspiration", "individuality", "courage", "quote"],
    "details": "A motivational quote on paving your own way by Ralph Waldo Emerson."
  },
  "12_9_2024": {
    "date": "12_9_2024",
    "quote": "Dream big and dare to fail.",
    "author": "Norman Vaughan",
    "tags": ["dreams", "failure", "quote"]
  },
  "13_9_2024": {
    "date": "13_9_2024",
    "quote": "It always seems impossible until it's done.",
    "author": "Nelson Mandela",
    "tags": ["perseverance", "possibility", "quote"]
  },
  "14_9_2024": {
    "date": "14_9_2024",
    "quote": "If you can dream it, you can achieve it.",
    "author": "Zig Ziglar",
    "tags": ["dreams", "achievement", "ZigZiglar", "quote"]
  },
  "15_9_2024": {
    "date": "15_9_2024",
    "quote": "The purpose of our lives is to be happy.",
    "author": "Dalai Lama",
    "tags": ["life", "happiness", "DalaiLama", "purpose", "quote"],
    "details": "A simple yet profound quote on the essence of life from the Dalai Lama."
  },
  "16_9_2024": {
    "date": "16_9_2024",
    "quote": "What you get by achieving your goals is not as important as what you become by achieving your goals.",
    "author": "Zig Ziglar",
    "tags": ["goals", "achievement", "ZigZiglar", "quote"]
  },
  "17_9_2024": {
    "date": "17_9_2024",
    "quote": "To accomplish great things, we must not only act, but also dream; not only plan, but also believe.",
    "author": "Anatole France",
    "tags": ["action", "dreams", "AnatoleFrance", "quote"]
  },
  "18_9_2024": {
    "date": "18_9_2024",
    "quote": "What lies behind us and what lies before us are tiny matters compared to what lies within us.",
    "author": "Ralph Waldo Emerson",
    "tags": ["self", "strength", "wisdom", "RalphWaldoEmerson", "quote"],
    "details": "A motivational quote on inner strength by Ralph Waldo Emerson."
  },
  "19_9_2024": {
    "date": "19_9_2024",
    "quote": "Strive not to be a success, but rather to be of value.",
    "author": "Albert Einstein",
    "tags": ["success", "value", "life", "AlbertEinstein", "quote"],
    "details": "A meaningful quote on the true measure of success from Albert Einstein."
  },
  "20_9_2024": {
    "date": "20_9_2024",
    "quote": "It is never too late to be what you might have been.",
    "author": "George Eliot",
    "tags": ["change", "inspiration", "self", "GeorgeEliot", "quote"],
    "details": "A quote on the possibilities of transformation from George Eliot.",
    "embed": `<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Finspiredailyquotes.online%2Fposts%2Fpfbid02K2TczNyMg6JhH5wxHcak3zAPTYGfdw6853qrkKkPPWRoLMKys2Z3ZDJ3rrTFX5Yml&show_text=true&width=500" width="500" height="660" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>`
  },
  "21_9_2024": {
    "date": "21_9_2024",
    "quote": "Do what you can, with what you have, where you are.",
    "author": "Theodore Roosevelt",
    "tags": ["perseverance", "self", "motivation", "quote"],
    "details": "A practical motivational quote from Theodore Roosevelt."
  },
  "22_9_2024": {
    "date": "22_9_2024",
    "quote": "Our greatest glory is not in never falling, but in rising every time we fall.",
    "author": "Confucius",
    "tags": ["perseverance", "glory", "Confucius", "quote"]
  },
  "23_9_2024": {
    "date": "23_9_2024",
    "quote": "In three words I can sum up everything I've learned about life: it goes on.",
    "author": "Robert Frost",
    "tags": ["life", "wisdom", "resilience", "RobertFrost", "quote"],
    "details": "A succinct and insightful quote from poet Robert Frost."
  },
  "24_9_2024": {
    "date": "24_9_2024",
    "quote": "Do not wait for leaders; do it alone, person to person.",
    "author": "Mother Teresa",
    "tags": ["leadership", "action", "change", "MotherTeresa", "quote"],
    "details": "A quote on personal responsibility and action from Mother Teresa."
  },
  "25_9_2024": {
    "date": "25_9_2024",
    "quote": "Success is how high you bounce when you hit bottom.",
    "author": "George S. Patton",
    "tags": ["success", "resilience", "life", "GeorgeSPatton", "quote"],
    "details": "A motivational quote on resilience from General George S. Patton."
  },
  "26_9_2024": {
    "date": "26_9_2024",
    "quote": "Happiness is not something ready-made. It comes from your own actions.",
    "author": "Dalai Lama",
    "tags": ["happiness", "life", "action", "DalaiLama", "quote"],
    "details": "A reminder that happiness is self-created, from the Dalai Lama."
  },
  "27_9_2024": {
    "date": "27_9_2024",
    "quote": "Life opens up opportunities to you, and you either take them or you stay afraid of taking them.",
    "author": "Jim Carrey",
    "postPicture": "https://i.ibb.co/hWPFKfK/isq-quote.png",
    "tags": ["opportunities", "greatness", "JimCarrey", "quote"]
  },
  "28_9_2024": {
    "date": "28_9_2024",
    "quote": "You must be the change you wish to see in the world.",
    "author": "Mahatma Gandhi",
    "tags": ["change", "action", "world", "MahatmaGandhi", "quote"],
    "details": "Gandhi's famous quote about personal responsibility in driving global change."
  },
  "29_9_2024": {
    "date": "29_9_2024",
    "quote": "Spread love everywhere you go. Let no one ever come to you without leaving happier.",
    "author": "Mother Teresa",
    "tags": ["love", "happy", "MotherTeresa", "quote"]
  },
  "30_9_2024": {
    "date": "30_9_2024",
    "quote": "Darkness cannot drive out darkness: only light can do that. Hate cannot drive out hate: only love can do that.",
    "author": "Martin Luther King Jr.",
    "tags": ["darkness", "love", "MartinLutherKingJr", "quote"]
  },
  "31_9_2024": {
    "date": "31_9_2024",
    "quote": "Do one thing every day that scares you.",
    "author": "Eleanor Roosevelt",
    "tags": ["scare", "everyday", "EleanorRoosevelt", "quote"]
  },
  "1_10_2024": {
    "date": "1_10_2024",
    "quote": "You cannot shake hands with a clenched fist.",
    "author": "Indira Gandhi",
    "tags": ["attack", "agreement", "IndiraGandhi", "quote"]
  },
  "2_10_2024": {
    "date": "2_10_2024",
    "quote": "Happiness can be found, even in the darkest of times, if one only remembers to turn on the light",
    "author": "Albus Dumbledore",
    "tags": ["happiness", "light", "AlbusDumbledore", "HarryPotter", "quote", "movies"]
  },
  "3_10_2024": {
    "date": "3_10_2024",
    "quote": "Let us sacrifice our today so that our children can have a better tomorrow.",
    "author": "A. P. J. Abdul Kalam",
    "tags": ["sacrifice", "bettertomorrow", "APJAbdulKalam", "quote"]
  },
  "4_10_2024": {
    "date": "4_10_2024",
    "quote": "You are the architect of your life.",
    "details": "Famous quote from India's Marathi Language - 'तूच आहेस तुझ्या जीवनाचा शिल्पकार'",
    "author": "Wamanrao Pai",
    "tags": ["life", "WamanraoPai", "quote"]
  },
  "5_10_2024": {
    "date": "5_10_2024",
    "quote": "Whatever the mind of man can conceive and believe, it can achieve.",
    "author": "Napoleon Hill",
    "tags": ["belief", "achieve", "NapoleonHill", "quote"]
  },
  "6_10_2024": {
    "date": "6_10_2024",
    "quote": "Two roads diverged in a wood, and I—I took the one less traveled by, And that has made all the difference.",
    "author": "Robert Frost",
    "tags": ["roads", "choices", "RobertFrost", "quote"]
  },
  "7_10_2024": {
    "date": "7_10_2024",
    "quote": "I attribute my success to this: I never gave or took any excuse.",
    "author": "Florence Nightingale",
    "tags": ["success", "noexcuse", "FlorenceNightingale", "quote"]
  },
  "8_10_2024": {
    "date": "8_10_2024",
    "quote": "Success is not how high you have climbed, but how you make a positive difference to the world.",
    "author": "Roy T. Bennett",
    "tags": ["success", "impact", "RoyTBennett", "quote"]
  },
  "9_10_2024": {
    "date": "9_10_2024",
    "quote": "Every strike brings me closer to the next home run.",
    "author": "Babe Ruth",
    "tags": ["success", "progress", "BabeRuth", "quote"]
  },
  "10_10_2024": {
    "date": "10_10_2024",
    "quote": "In the end, we will remember not the words of our enemies, but the silence of our friends.",
    "author": "Martin Luther King Jr.",
    "tags": ["friendship", "justice", "civilrights", "MartinLutherKingJr", "quote"]
  },
  "11_10_2024": {
    "date": "11_10_2024",
    "quote": "Life isn't about getting and having, it's about giving and being.",
    "author": "Kevin Kruse",
    "tags": ["life", "generous", "KevinKruse", "quote"]
  },
  "12_10_2024": {
    "date": "12_10_2024",
    "quote": "The most common way people give up their power is by thinking they don't have any.",
    "author": "Alice Walker",
    "tags": ["power", "unawareness", "AliceWalker", "quote"]
  },
  "13_10_2024": {
    "date": "13_10_2024",
    "quote": "Every child is an artist.  The problem is how to remain an artist once he grows up.",
    "author": "Pablo Picasso",
    "tags": ["artist", "change", "PabloPicasso", "quote"]
  },
  "14_10_2024": {
    "date": "14_10_2024",
    "quote": "You can never cross the ocean until you have the courage to lose sight of the shore.",
    "author": "Christopher Columbus",
    "tags": ["courage", "achievement", "ChristopherColumbus", "quote"]
  },
  "15_10_2024": {
    "date": "15_10_2024",
    "quote": "There is only one way to avoid criticism: do nothing, say nothing, and be nothing.",
    "author": "Aristotle",
    "tags": ["criticism", "nothing", "Aristotle", "quote"]
  },
  "16_10_2024": {
    "date": "16_10_2024",
    "quote": "What you get by achieving your goals is not as important as what you become by achieving your goals.",
    "author": "Zig Ziglar",
    "tags": ["goals", "achievement"]
  },
  "17_10_2024": {
    "date": "17_10_2024",
    "quote": "To accomplish great things, we must not only act, but also dream; not only plan, but also believe.",
    "author": "Anatole France",
    "tags": ["action", "dreams"]
  },
  "18_10_2024": {
    "date": "18_10_2024",
    "quote": "The future belongs to those who believe in the beauty of their dreams.",
    "author": "Eleanor Roosevelt",
    "tags": ["future", "dreams"]
  },
  "19_10_2024": {
    "date": "19_10_2024",
    "quote": "It does not matter how slowly you go as long as you do not stop.",
    "author": "Confucius",
    "tags": ["perseverance", "persistence"]
  },
  "20_10_2024": {
    "date": "20_10_2024",
    "quote": "Success is not final, failure is not fatal: It is the courage to continue that counts.",
    "author": "Winston Churchill",
    "tags": ["success", "perseverance"]
  },
  "21_10_2024": {
    "date": "21_10_2024",
    "quote": "The only way to achieve the impossible is to believe it is possible.",
    "author": "Charles Kingsleigh",
    "tags": ["belief", "possibility"]
  },
  "22_10_2024": {
    "date": "22_10_2024",
    "quote": "Our greatest glory is not in never falling, but in rising every time we fall.",
    "author": "Confucius",
    "tags": ["perseverance", "glory"]
  },
  "23_10_2024": {
    "date": "23_10_2024",
    "quote": "Act as if what you do makes a difference. It does.",
    "author": "William James",
    "tags": ["action", "impact"]
  },
  "24_10_2024": {
    "date": "24_10_2024",
    "quote": "Success usually comes to those who are too busy to be looking for it.",
    "author": "Henry David Thoreau",
    "tags": ["success", "busyness"]
  },
  "25_10_2024": {
    "date": "25_10_2024",
    "quote": "The best revenge is massive success.",
    "author": "Frank Sinatra",
    "tags": ["success", "revenge"]
  },
  "26_10_2024": {
    "date": "26_10_2024",
    "quote": "Opportunities don't happen. You create them.",
    "author": "Chris Grosser",
    "tags": ["opportunity", "action"]
  },
  "27_10_2024": {
    "date": "27_10_2024",
    "quote": "I have the simplest tastes. I am always satisfied with the best.",
    "author": "Oscar Wilde",
    "tags": ["taste", "satisfaction"]
  },
  "28_10_2024": {
    "date": "28_10_2024",
    "quote": "The only impossible journey is the one you never begin.",
    "author": "Tony Robbins",
    "tags": ["journey", "action"]
  },
  "29_10_2024": {
    "date": "29_10_2024",
    "quote": "I find that the harder I work, the more luck I seem to have.",
    "author": "Thomas Jefferson",
    "tags": ["hard work", "luck"]
  },
  "30_10_2024": {
    "date": "30_10_2024",
    "quote": "Success is not in what you have, but who you are.",
    "author": "Bo Bennett",
    "tags": ["success", "character"]
  },
  "1_11_2024": {
    "date": "1_11_2024",
    "quote": "Life is what happens when you're busy making other plans.",
    "author": "John Lennon",
    "tags": ["life", "plans"]
  },
  "2_11_2024": {
    "date": "2_11_2024",
    "quote": "The best way to predict the future is to invent it.",
    "author": "Alan Kay",
    "tags": ["future", "innovation"]
  },
  "3_11_2024": {
    "date": "3_11_2024",
    "quote": "You only live once, but if you do it right, once is enough.",
    "author": "Mae West",
    "tags": ["life", "inspiration"]
  }
};
