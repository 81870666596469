export interface IInitSEO {
  title?: string;
  additionalKeywords?: string;
  description?: string;
  image?: string;
}

const DEFAULT_DESC = "Daily Quotes to start your day with";
const DEFAULT_KEYWORDS =
  "quotes, thought of the day, quotations, inspiring quotes, inspire to be, quote of the day, day quotes, gratitude quotes, morning inspirational quotes, believe, thank you, appreciation quotes, quotes about change, good day to day quotes, thinking, 2024 quotes, thanksgiving, movie lines, iconic movie lines, iconic lines";

export const initSEO = (attrs: IInitSEO = {}) => {
  if (attrs?.title) {
    document.title = attrs?.title;
    document.getElementById("metaog:title") &&
      ((document.getElementById("metaog:title") as HTMLMetaElement).content =
        attrs?.title);
    document.getElementById("meta:twitter:card") &&
      ((
        document.getElementById("meta:twitter:card") as HTMLMetaElement
      ).content = attrs?.title);
  }

  if (attrs?.additionalKeywords) {
    document.getElementById("metakeywords") &&
      ((document.getElementById("metakeywords") as HTMLMetaElement).content =
        attrs?.additionalKeywords + ", " + DEFAULT_KEYWORDS);
  }

  if (attrs?.description) {
    document.getElementById("metadescription") &&
      ((document.getElementById("metadescription") as HTMLMetaElement).content =
        attrs?.description + ", " + DEFAULT_DESC);
    document.getElementById("meta:twitter:description") &&
      ((
        document.getElementById("meta:twitter:description") as HTMLMetaElement
      ).content = attrs?.description + ", " + DEFAULT_DESC);
  }

  if (attrs?.image) {
    document.getElementById("metaog:image") &&
      ((document.getElementById("metaog:image") as HTMLMetaElement).content =
        attrs?.image);
    document.getElementById("meta:twitter:image") &&
      ((
        document.getElementById("meta:twitter:image") as HTMLMetaElement
      ).content = attrs?.image);
  }

  document.getElementById("metaog:url") &&
    ((document.getElementById("metaog:url") as HTMLMetaElement).content =
      window.location.href);
};
