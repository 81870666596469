import React from 'react';
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { ILoaderData } from "../Models/LoaderData";
import { Container, Typography, Card, CardContent, Grid } from '@mui/material';
import { matchTags } from "../Quotes/Main";
import { matchTagsLines } from '../IconicLines/Main';
import { IQuote } from '../Quotes/QuotesDir';
import { IIconicLines } from '../IconicLines/IconicLinesDir';
import { CUSTOM_EVENTS, GAEvent } from '../Components/GoogleAnalyticsEvents';
import { dateFormat, parseDate } from '../Util/Date';
import { initSEO } from '../Util/SEOInit';


export default function Tags() {
  const loaderData = useLoaderData() as ILoaderData;
  const navigate = useNavigate();
  const tag = loaderData.tag as string;
  const type = loaderData.type || "iconic-lines";
  const quotes = matchTags(tag);
  const lines = matchTagsLines(tag);

  const selectedList = type === "iconic-lines" ? lines : quotes;
  const typeLabel = type === "iconic-lines" ? "Iconic Line" : "Quote";

  const onPostClick = (post: IQuote | IIconicLines, ev: any) => {
    if (ev?.target?.classList?.contains('post-tag')) {
      return;
    }
    const url = type === 'quotes' ? `/date/${post.date}` : `/iconic-lines/date/${post.date}`;
    GAEvent(CUSTOM_EVENTS.USERINT_POST_VISIT, { newDate: parseDate(post.date), dateSlug: post.date });
    navigate(url);
  };

  initSEO({
    title: `Search for ${typeLabel} by the tag ${tag}`,
    additionalKeywords: `${tag}`,
    description: `All ${typeLabel}s related to ${tag}`
  });

  return (
    <>
      <Container>
        <Typography variant="h4" gutterBottom marginBottom={5}>
          {typeLabel} with #{tag}
        </Typography>
        <Typography variant="body1" marginBottom={3}>{selectedList.length} results found</Typography>
        <Grid container spacing={2}>
          {selectedList.length > 0 ? (
            selectedList.map((post) => (
              <Grid item xs={12} sm={6} md={4} key={post.date} sx={{ cursor: 'pointer' }} onClick={(ev) => onPostClick(post, ev)}>
                <Card sx={{
                  boxShadow: 3,  // Box shadow effect
                  borderRadius: 2,  // Rounded corners
                  transition: 'transform 0.3s ease', // Smooth hover effect
                  '&:hover': {
                    transform: 'scale(1.05)' // Slightly scale up on hover
                  }
                }}>
                  <CardContent>
                    <Typography variant="body1">{(post as IQuote).quote || (post as IIconicLines).iconicLine}</Typography>
                    <Typography variant="body2" marginTop={2}><em>- {post.author}</em></Typography>
                    <Typography variant="body2" marginTop={2}>{typeLabel} of the day: {dateFormat(parseDate(post.date) as Date)}</Typography>
                    <Typography variant="caption" color="textSecondary" marginTop={2}>
                      {post.tags.map((el) => {
                        return <React.Fragment key={el}>
                          <Link className='post-tag' to={`/${type}/tags/${el}`}>{`#${el}`}</Link>&nbsp;&nbsp;
                        </React.Fragment>
                      })}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="body1">No posts found for this hashtag.</Typography>
          )}
        </Grid>
      </Container>
    </>
  );
}
